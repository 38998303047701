#navbarItem {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 35px;
  letter-spacing: 0.06em;
  color: #e6f2fb !important;
}

#navbarItem:hover {
  background-color: rgba(0, 5, 8, 0) !important;
  text-decoration: underline;
}

#navbarItem:active {
  color: #00DD72 !important;
}

#title {
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 300;
  font-size: 7vw;
  letter-spacing: 0.06em;
  color: #e6f2fb;
  margin-bottom: 0px!important;
  line-height: 1.125;
}


.subheading{
    font-family: Nunito;
    font-style: normal;
    font-weight: 200;
    font-size: calc(10px + 1.3vw);
    letter-spacing: 0.08em;
    color: rgb(28, 255, 189);
}

.subheading2{
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 200;
  font-size: calc(10px + 1.3vw);
  color: #E5F2FB;
}

.floating {  
  -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1.5s both;
  animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) 1.5s both;
}

.rotatecenter {
	-webkit-animation: rotate-center 1s ease-in-out 1s both;
	        animation: rotate-center 1s ease-in-out 1s both;
}

@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}


@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/* @keyframes floating {
    from { transform: translate(0,  0px); }
    65%  { transform: translate(-10px, -20px); }
    to   { transform: translate(0, -0px); }    
} */

.cardss{
    position: relative;
    transform-style: preserve-3d;
    width: calc(100px + 20%);
    margin-top: -25vw;
    margin-right: 10vw;
}

#custombtn{
  font-family: 'Nunito';
  background-color: #161F2F;
  border-width: 2px;
  color: #E5F2FB;
  border-color: #C6D3E7;
  letter-spacing: 0.08em;
}

#custombtnS{
  font-family: 'Nunito';
  background-color: #161F2F;
  border-width: 2px;
  color: #E5F2FB;
  border-color: #00DD72;
  letter-spacing: 0.08em;
}


#custombtnW{
  font-family: 'Nunito';
  background-color: #161F2F;
  border-width: 2px;
  color: #E5F2FB;
  border-color: rgb(255, 40, 40);
  letter-spacing: 0.08em;
}

#custombtn:hover{
  background-color: rgb(28, 255, 189);
  border-color: rgb(28, 255, 189);
  color: rgb(55, 62, 68);
}

#custombtnS:hover{
  background-color: #00DD72;
  border-color: #00DD72;
  color: #E5F2FB;
}

#custombtnW:hover{
  background-color: rgb(255, 40, 40);
  border-color: rgb(255, 40, 40);
  color: #E5F2FB;
}

#custombtn:active{
  color: rgb(56, 61, 65);
  background-color: rgb(28, 255, 189);
}

#custombtnS:active{
  color: #E5F2FB;
  background-color: rgb(78, 255, 169);
}

#custombtnW:active{
  color: #E5F2FB;
  background-color: rgb(253, 87, 87);
}

#custombtn:focus{
  color: rgb(255, 255, 255);
  border-color: rgb(30, 214, 162);
  background-color: rgb(21, 180, 135);
}

#custombtnS:focus{
  color: #E5F2FB;
  border-color:rgb(17, 199, 93);
}

#custombtnW:focus{
  color: #E5F2FB;
  border-color: rgb(255, 40, 40);
}

.subheader{
  padding: calc(10px + 2%);
  padding-bottom: 0px;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: 300;
  font-size: calc(12px + 1.5vw);
  letter-spacing: 0.08em;
  color: #e6f2fb;
}

.svgg{
  background-color: rgb(19, 28, 42);
  color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2325292f' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23292d34'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.iclick{
  transition: all .2s ease-in-out; 
}

.iclick:hover{ 
  transform: scale(1.3); 
}

@media only screen and (max-width: 1024px) {
  .cluster {
    padding: 1.75rem !important;
  }
  .cardss{
    margin-right: 2vw;
  }
}

